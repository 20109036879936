import styled from 'styled-components';
import { Col } from 'reactstrap';
import { em } from 'polished';
import StyledHamburger from '@src/components/StyledHamburger';

export const Menu = styled.nav`
    @media (max-width: 991px) {
        display: none;
    }
`;

export const Table = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 40px;
`;

export const Column = styled.div`
    position: relative;
    z-index: 999;
    text-align: center;
    padding: .3em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-of-type {
        padding-right: 0;
    }

    > div, > a {
        padding: 0 .2em;

        > img, > span {
            display: inline-block;
            vertical-align: middle;
            font-size: 0.86em;
            font-weight: 500;
        }
    }

    &:first-of-type {
        > div, > a {
            border-left: 0;
        }
    }

    &:only-child {
        nav {
            left: 0 !important;
        }
    }

    &:nth-last-child(-n+3) {
        nav {
            right: 0;
            left: initial;
        }
    }

    &:hover {
        > div > nav {
            display: block;
        }
    }
`;

export const AllProducts = styled(Col)`
    position: static;
    flex: 0 0 171px;
    max-width: 171px;
    z-index: 1000;
    border: 1px solid #ccd8e2;
    border-radius: 2px 2px 0 0;

    &:hover {
        border: 1px solid #ccd8e2;
        border-radius: 0;
        border-bottom: 0;
    }

    > button {
        color: var(--dark);
        position: relative;
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: space-between;
        outline: 0;
        height: 38px;
        text-align: left;
        width: 100%;
        padding: 1px 9px 0 12px;

        > b {
            display: inline-block;
            vertical-align: middle;
            font-size: ${em('12px')};
            font-weight: 500;
        }
    }

    ${StyledHamburger} {
        margin: -1px 0 0 0;
        color: var(--dark);
    }
`;

export const AllSegments = styled(Col)`
    flex: 0 0 calc(100% - 171px);
    max-width: ${({ isfull }) => (isfull ? '100%' : 'calc(100% - 171px)')};
    width: 100%;
    height: 40px;
    position: relative;
    z-index: 1000;
`;

export const BlockMenu = styled.div`
    color: var(--dark);
    font-weight: 700;
    font-size: ${em('14px')};
    display: block;
    text-align: center;
    cursor: pointer;
    
    &:hover {
        color: var(--primary);
    }
`;

export const Link = styled.a`
    color: var(--dark);
    font-weight: 500;
    font-size: ${em('14px')};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    height: 38px;
    
    &:hover{
        color: var(--primary);
    }
`;
