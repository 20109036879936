import { useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { Row } from 'reactstrap';
import sortBy from 'lodash/sortBy';
import StyledContainer from '@src/components/StyledContainer';
import StyledHamburger from '@src/components/StyledHamburger';
import { generateUrl, getCategoryTitle } from '@src/utils';
import { useI18n } from '@src/locales';
import * as Styles from './menu.styled';

const shop = process.env.NEXT_PUBLIC_SHOP_NAME;
const subs = shop === 'gadgets' ? 'products_groups' : 'sub_segments';

const SubMenu = dynamic(() => import('./SubMenu'), { ssr: true });
const SubSegment = dynamic(() => import('./SubSegment'), { ssr: true });

/**
 * Menu component
 * @description Contains the menu of segments page (4 pages max) and the segment menu.
 * @author Youssef Lahssini
 */
const Menu = ({ layout }) => {
    const [showed, setShowed] = useState(false);
    const { locale } = useRouter();
    const { t } = useI18n();

    /**
     * This function toggle the display of submenu (show/hide)
     * @param boolean showed State of display
     */
    function handleSubmenu(value) {
        return () => {
            setShowed(value);
        };
    }

    const otherSegments = useMemo(
        () => sortBy(
            layout?.submenu || [],
            [(s) => s.resellers.find((r) => r.reseller_id === process.env.NEXT_PUBLIC_SHOP_ID)?.order || Infinity],
        ).filter((s) => s?.id !== process.env.NEXT_PUBLIC_ALL_PRODUCTS_ID),
        [layout?.submenu],
    );

    return (
        <Styles.Menu className="menu">
            <StyledContainer fluid>
                <Row className="align-items-center position-relative" noGutters>
                    <Styles.AllProducts xs="2" className="p-0" onMouseEnter={handleSubmenu(true)} onMouseLeave={handleSubmenu(false)}>
                        <button aria-label={t('allCategories')} type="button">
                            <b className="allCategories">{t('allCategories')}</b>
                            <StyledHamburger>
                                <span />
                            </StyledHamburger>
                        </button>

                        <SubMenu showed={showed} handleSubmenu={handleSubmenu} />
                    </Styles.AllProducts>

                    <Styles.AllSegments className="pr-0" xs="10">
                        <Styles.Table>
                            {
                                otherSegments.map((segment) => {
                                    const title = getCategoryTitle(segment, locale);

                                    return (
                                        <Styles.Column className="segmentMenu" key={segment.id}>
                                            <Styles.BlockMenu>
                                                <Link href={generateUrl({ levelOne: segment, locale })} passHref prefetch={false}>
                                                    <Styles.Link className="linkSegmentMenu">
                                                        {title}
                                                    </Styles.Link>
                                                </Link>
                                                <SubSegment segment={segment} data={segment?.[subs] || []} />
                                            </Styles.BlockMenu>
                                        </Styles.Column>
                                    );
                                })
                            }
                        </Styles.Table>
                    </Styles.AllSegments>
                </Row>
            </StyledContainer>
        </Styles.Menu>
    );
};

export default Menu;
