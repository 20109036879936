import styled from 'styled-components';
import { size } from 'polished';
import { GREY } from '../../assets/styles/settings.styled';

const StyledHamburger = styled.span`
    display: block;
    width: 12px;
    padding-right: .2em;
    padding-top: .2em;
    font-size: 0.75em;
    float: right;

    &:before, &:after, > span {
        content: "";
        ${size('2px', '12px')}
        border-radius: 1px;
        background-color: ${GREY};
        display: block;
        transition: all .2s;
        position: relative;
    }

    > span {
        margin: 5px 0;
    }
`;

export default StyledHamburger;
